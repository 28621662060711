export default [
    {
        label: 'No.',
        field: 'orderNo',
        sort: true
    },
    {
        label: 'Remote ID',
        field: 'deviceId',
        sort: true
    },
    {
        label: 'Display',
        field: 'isVisible',
        sort: true
    },
    {
        label: 'Note',
        field: 'note',
        sort: true
    }
];

<template>
    <div class="main-container">
      <div class="h5-container">
        <h5 class="pageTitle" v-html="pageTitle"></h5>
        <button class="custom-btn-box btn-add" @click="addButton">추가</button>
      </div>
      <mdb-container class="search-container">
        <mdb-row>
          <div class="ml-3">
            <mdb-select
                class="search-select"
                outline
                resetBtn
                v-model="selectOptions"
                placeholder="검색 항목"
                @getValue="getSelectValue"/>
          </div>
          <mdb-col>
            <mdb-input type="text" v-model="search" outline placeholder="검색어를 입력하세요."></mdb-input>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      <mdb-container>
        <mdb-datatable-2
            v-model="data"
            class="text-center"
            striped
            bordered
            hover            
            :searching="{value: search, field: fieldName} "
            @selected='onSelected($event)'
            noFoundMessage="데이터가 없습니다."/>            
      </mdb-container>
    </div>
  </template>
  
  <script>
  import { mdbContainer, mdbDatatable2,mdbRow,mdbCol,mdbInput,mdbSelect } from "mdbvue";
  import drone_list from "@/assets/data/columns/drone_list";
  import axios from "@/axios";
  
  export default {
    name: "DroneDeviceList",
    components: {
      mdbContainer,
      mdbDatatable2,
      mdbRow,
      mdbCol,
      mdbInput,
      mdbSelect,
    },
    data() {
      return {
        token: this.$store.state.token.access_token,
        pageTitle: 'Remote ID 목록',
        data: {
          columns: drone_list,
          rows: []
        },
        search: '',
        fieldName: '',
        selectOptions: [
          {text: "이름", value: "name"},
        ],
      }
    },
    mounted() {        
      this.init();
      //페이지네이션 요소 숨기기
      const paginationElement = document.querySelector('.dataTables_info.pt-2 mx-sm-4.d-inline-block');
      if (paginationElement) {
        paginationElement.style.display = 'none';
      }
    },
    methods: {
      init() {        
        this.getDroneDevice()
      },
      getDroneDevice() {        
        const self = this;
        self.data.rows.splice(0);
        let orderNo = 0;
  
        const config = {
          method: 'get',
          url: '/drones/device',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`
          },
        };        
 
        axios(config)
            .then((querySnapshot) => {
              if (querySnapshot.data.size === 0) {
                return
              }
              querySnapshot.data.forEach((doc) => {
                orderNo++;
                doc['orderNo'] = orderNo;
                if (doc.isVisible) {
                    doc['isVisible'] = 'O';
                } else {
                    doc['isVisible'] = 'X';
                }
                self.data.rows.push(doc)
              })
            })
      },
      addButton() {
        const self = this;
        self.$router.push({name: 'droneDeviceInfo', params: {source: 'add'}})
      },
      onSelected(value) {
        const self = this;
        self.$router.push({name: 'droneDeviceInfo', params: {id: value.uuid, source: 'update'}})
      },
      getSelectValue(value) {
        const self = this;
        self.fieldName = value;
      },
      
    }
  }
  </script>
  
  <style scoped>
  .main-container {
    padding: 15px;
  }
  
  .pageTitle {
    font-weight: bold;
  }
  
  .search-select {
    width: 150px;
  }

  /* footer */
  .info-footer {
    background:#ffffff;
    border-radius: 0 0 8px 8px;
    height: 73px;
    padding: 17px 16px 16px;
    margin-left: auto;
   }
  
  .info-footer .btn-add {
    margin: 0;
    padding: 11px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    border: 0;
    width: 67px;
    
  }

  .h5-container {
    display: flex;    
    align-items: center;    
  }
  .h5-container button {
    margin-left: 15px;
    background-color: #4caf50;
    color: white;
    padding: 6px 10px;
    border: 0;
  }
  
.dataTables_info.pt-2.mx-sm-4.d-inline-block {
    display: none !important;
}

  </style>
  